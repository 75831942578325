<template>
    <v-app>
        <v-img src="@/assets/img/index.png" min-height="100vh" width="100%" class="ks-fixed"></v-img>
        <v-sheet height="100vh" class="z-index--2000" color="transparent">
            <v-container>
                <v-row>
                    <v-col cols="auto" class="pt-6">
                        <h1 class="text--white">Admin <br>
                            Bruder-Klaus-Kapelle <br>
                            Schwägalp</h1>
                    </v-col>
                </v-row>
            </v-container>
            <router-view/>
        </v-sheet>
    </v-app>
</template>

<script>

export default {
    name: 'App',

    data: () => ({
        //
    }),
};
</script>
