import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Calendar from '../views/Calendar.vue'
import AssociationCalendar from '../views/AssociationCalendar.vue'
import User from '../views/User.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (store.state.loginState){
        next('/calendar')
      } else {
        next()
      }
    }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    beforeEnter: (to, from, next) => {
      if (store.state.loginState){
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/association-calendar',
    name: 'AssociationCalendar',
    component: AssociationCalendar,
    beforeEnter: (to, from, next) => {
      if (store.state.loginState){
        next()
      } else {
        next('/')
      }
    }
  },
  {
    path: '/user',
    name: 'User',
    component: User,
    beforeEnter: (to, from, next) => {
      if (store.state.loginState){
        next()
      } else {
        next('/')
      }
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
