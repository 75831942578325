export default {
  currentUser:null,
  loginState: null,
  events:[],
  associationNews:[],
  currentNews: {
    date:null,
    time:null,
    location:null,
    title:null,
    description:null,
  },
  currentEvent:{
    date:null,
    time:null,
    referent:null,
    refLocation:null,
    name:null,
    relDenom:null
  },
  emptyNews: {
    date:null,
    time:null,
    location:null,
    title:null,
    description:null,
  },
  emptyEvent:{
    date:null,
    time:null,
    referent:null,
    refLocation:null,
    name:null,
    relDenom:null
  },
  passwordConfirmMsg: false,
  emailConfirmMsg: false,
};
