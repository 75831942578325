<template>
    <v-container class="mt-16">
        <v-row>
            <v-col cols="12" md="4" offset-md="2">
                <v-row dense>
                    <v-col cols="12">
                        <v-btn block
                               v-on:click="logoutUser()">Logout
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block link to="/user">User</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block link to="/calendar">Gottesdienste</v-btn>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-card color="rgba(255, 255, 255, 0.9)">
                            <v-card-text>
                                <v-form ref="form">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu
                                                    ref="menu"
                                                    :close-on-content-click="false"
                                                    v-model="menu"
                                                    :nudge-right="40"
                                                    :return-value.sync="newsDate"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-text-field
                                                            :rules="[rules.required]"
                                                            label="Datum"
                                                            v-model="newsDate"
                                                            prepend-icon="event"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            readonly>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="newsDate"
                                                        @input="$refs.menu.save(newsDate)"
                                                        locale="de-DE">
                                                </v-date-picker>
                                            </v-menu>
                                            <v-menu
                                                    ref="timeMenu"
                                                    v-model="timeMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="newsTime"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-text-field :rules="[rules.required]"
                                                                  v-model="newsTime"
                                                                  label="Uhrzeit"
                                                                  prepend-icon="access_time"
                                                                  v-bind="attrs"
                                                                  v-on="on"
                                                                  readonly
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                        v-if="timeMenu"
                                                        v-model="newsTime"
                                                        format="24hr"
                                                        full-width
                                                        @click:minute="$refs.timeMenu.save(newsTime)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                    v-model="newsTitle"
                                                    color="primary"
                                                    label="Titel">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-textarea
                                                    v-model="newsDescription"
                                                    rows="4"
                                                    color="primary"
                                                    label="Beschreibung">
                                            </v-textarea>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                    v-model="newsLocation"
                                                    :rules="[rules.required]"
                                                    color="primary"
                                                    label="Ort">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn block v-on:click="addUpdateNews">Save</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="5" lg="4" offset-md="1">
                <v-card color="rgba(255, 255, 255, 0.9)">
                    <v-toolbar>
                        <v-toolbar-title class="text--primary font-weight-medium">Vereins-News</v-toolbar-title>
                    </v-toolbar>
                    <v-list three-line color="transparent">
                        <v-list-item
                                v-for="(news, index) in associationNews"
                                :key="index"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ news.date }} <span class="mr-2"></span>  {{ news.time }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ news.title }}, {{ news.location }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle >
                                    <p v-html="news.description" class="newLine"></p>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row dense>
                                    <v-col cols="auto">
                                        <v-btn icon
                                               ripple
                                               color="primary"
                                               @click.stop="getNews(news)">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn icon
                                               ripple
                                               color="primary"
                                               @click.stop="deleteNews(news.id)">
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapActions, mapState, mapMutations} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import firebase from 'firebase';

export default {
    name: 'AssociationCalendar',
    data() {
        return {
            updateMode:false,
            menu: false,
            timeMenu: false,
            rules: {
                required: value => !!value || 'Dieses Feld muss ausgefüllt sein.',
            },
        };
    },
    mounted() {
        let self = this;
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                self.associationNews = [];
                self.readNewsAll();
            } else {
                self.$router.push('/');
            }
        });
    },
    computed: {
        ...mapState({
            associationNews: state => state.associationNews,
            emptyNews: state => state.emptyNews,
        }),
        ...mapFields({
            associationNews:'associationNews',
            loginState: 'loginState',
            newsDate: 'currentNews.date',
            newsTime: 'currentNews.time',
            newsLocation: 'currentNews.location',
            newsTitle: 'currentNews.title',
            newsDescription: 'currentNews.description',
            currentNews: 'currentNews',
        }),
    },
    methods: {
        ...mapActions({
            readNewsAll: 'readNewsAll',
            createNews: 'createNews',
            updateNews: 'updateNews',
            deleteNews: 'deleteNews',
            logoutUser: 'logoutUser',
        }),
        ...mapMutations({
            setCurrentNews: 'setCurrentNews',
        }),
        getNews(news){
            this.updateMode = true
            this.setCurrentNews(news)
        },
        addUpdateNews() {
            if (this.$refs.form.validate()) {
                if (!this.updateMode){
                    this.createNews().then(() => {
                        this.currentNews = this.emptyNews;
                        this.$refs.form.reset();
                    });
                }
                else {
                    this.updateNews(this.currentNews.id).then(()=> {
                        this.updateMode = false
                        this.currentNews = this.emptyNews;
                        this.$refs.form.reset();
                    })
                }
            }
        },
    },
};
</script>
