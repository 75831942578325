<template>
    <v-container class="mt-16">
        <v-row>
            <v-col cols="12" md="4" offset-md="2">
                <v-row dense>
                    <v-col cols="12">
                        <v-btn block
                               v-on:click="logoutUser()">Logout
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block link to="/user">User</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block link to="/association-calendar">Vereins News</v-btn>
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12">
                        <v-card color="rgba(255, 255, 255, 0.9)">
                            <v-card-text>
                                <v-form ref="form">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-menu
                                                    ref="menu"
                                                    :close-on-content-click="false"
                                                    v-model="menu"
                                                    :nudge-right="40"
                                                    :return-value.sync="eventDate"
                                                    transition="scale-transition"
                                                    offset-y
                                                    min-width="290px">
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-text-field
                                                            :rules="[rules.required]"
                                                            label="Datum"
                                                            v-model="eventDate"
                                                            prepend-icon="event"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            readonly>
                                                    </v-text-field>
                                                </template>
                                                <v-date-picker
                                                        v-model="eventDate"
                                                        @input="$refs.menu.save(eventDate)"
                                                        locale="de-DE">
                                                </v-date-picker>
                                            </v-menu>
                                            <v-menu
                                                    ref="timeMenu"
                                                    v-model="timeMenu"
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    :return-value.sync="eventTime"
                                                    transition="scale-transition"
                                                    offset-y
                                                    max-width="290px"
                                                    min-width="290px"
                                            >
                                                <template v-slot:activator="{on, attrs}">
                                                    <v-text-field :rules="[rules.required]"
                                                                  v-model="eventTime"
                                                                  label="Uhrzeit"
                                                                  prepend-icon="access_time"
                                                                  v-bind="attrs"
                                                                  v-on="on"
                                                                  readonly
                                                    ></v-text-field>
                                                </template>
                                                <v-time-picker
                                                        v-if="timeMenu"
                                                        v-model="eventTime"
                                                        format="24hr"
                                                        full-width
                                                        @click:minute="$refs.timeMenu.save(eventTime)"
                                                ></v-time-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                    v-model="eventReferent"
                                                    :rules="[rules.required]"
                                                    color="primary"
                                                    label="Priester oder Pfarrer">
                                            </v-text-field>
                                            <v-text-field
                                                    v-model="eventRefLocation"
                                                    :rules="[rules.required]"
                                                    color="primary"
                                                    label="Ort">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field
                                                    v-model="eventName"
                                                    color="primary"
                                                    label="Zusätzlicher Name">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-radio-group v-model="eventRelDenom"
                                                           :mandatory="false"
                                                           :rules="[rules.required]"
                                                           label="Konfession des Gottesdienstes">
                                                <v-radio label="römisch katholisch" value="1"></v-radio>
                                                <v-radio label="evangelisch" value="3"></v-radio>
                                                <v-radio label="ökumenisch" value="2"></v-radio>
                                            </v-radio-group>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn block v-on:click="addUpdateEvent">Save</v-btn>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="5" lg="4" offset-md="1">
                <v-card color="rgba(255, 255, 255, 0.9)">
                    <v-toolbar>
                        <v-toolbar-title class="text--primary font-weight-medium">Agenda</v-toolbar-title>
                        <!--<v-spacer></v-spacer>
                        <v-btn icon ripple @click="deleteAllPreviousEvents">
                            <v-icon>delete</v-icon>
                        </v-btn>-->
                    </v-toolbar>
                    <v-list three-line color="transparent">
                        <v-list-item
                                v-for="(event, index) in events"
                                :key="index"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ event.date }}<span class="mr-2"></span> {{ event.time }}</v-list-item-title>
                                <v-list-item-subtitle>
                                    {{ event.referent }} {{ event.refLocation }}
                                </v-list-item-subtitle>
                                <v-list-item-subtitle v-if="event.name">
                                    {{ event.name }}
                                </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row dense>
                                    <v-col cols="auto">
                                        <v-btn icon
                                               ripple
                                               color="primary"
                                               @click.stop="getEvent(event)">
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="auto">
                                        <v-btn icon
                                               ripple
                                               color="primary"
                                               @click.stop="deleteEvent(event.id)">
                                            <v-icon>delete</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {mapActions, mapState, mapMutations} from 'vuex';
import {mapFields} from 'vuex-map-fields';
import firebase from 'firebase';

export default {
    name: 'Calendar',
    data() {
        return {
            updateMode:false,
            menu: false,
            timeMenu: false,
            rules: {
                required: value => !!value || 'Dieses Feld muss ausgefüllt sein.',
            },
        };
    },
    mounted() {
        let self = this;
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                self.readEventsAll();
            } else {
                self.$router.push('/');
            }
        });
    },
    computed: {
        ...mapState({
            events: state => state.events,
            emptyEvent: state => state.emptyEvent,
        }),
        ...mapFields({
            loginState: 'loginState',
            eventDate: 'currentEvent.date',
            eventTime: 'currentEvent.time',
            eventReferent: 'currentEvent.referent',
            eventRefLocation: 'currentEvent.refLocation',
            eventName: 'currentEvent.name',
            eventRelDenom: 'currentEvent.relDenom',
            currentEvent: 'currentEvent',
        }),
    },
    methods: {
        ...mapActions({
            readEventsAll: 'readEventsAll',
            createEvent: 'createEvent',
            updateEvent: 'updateEvent',
            deleteEvent: 'deleteEvent',
            logoutUser: 'logoutUser',
        }),
        ...mapMutations({
            setCurrentEvent: 'setCurrentEvent',
        }),
        getEvent(event){
            this.updateMode = true
            this.setCurrentEvent(event)
        },
        addUpdateEvent() {
            if (this.$refs.form.validate()) {
                if (!this.updateMode){
                    this.createEvent().then(() => {
                        this.currentEvent = this.emptyEvent;
                        this.$refs.form.reset();
                    });
                }
                else {
                    this.updateEvent(this.currentEvent.id).then(()=> {
                        this.updateMode = false
                        this.currentEvent = this.emptyEvent;
                        this.$refs.form.reset();
                    })
                }
            }
        },
    },
};
</script>
