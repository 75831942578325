<template>
    <v-container class="mt-16">
        <v-row>
            <v-col cols="12" md="4" offset-md="2">
                <v-row dense>
                    <v-col cols="12">
                        <v-btn block
                               v-on:click="logoutUser()">Logout
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block link to="/calendar">Gottesdienste</v-btn>
                    </v-col>
                    <v-col cols="12">
                        <v-btn block link to="/association-calendar">Vereins News</v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <v-card color="rgba(255, 255, 255, 0.9)">
                            <v-card-text>
                                <v-form ref="form">
                                    <v-row>
                                        <v-col cols="12" v-if="currentUserEmail">
                                            <v-text-field v-model="currentUserEmail"
                                                          label="E-Mail"
                                                          prepend-inner-icon="mail"
                                                          :rules="[rules.required, rules.mail]"
                                                          color="primary">
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn block v-on:click="updateUserMail(currentUserEmail)">Update Email
                                            </v-btn>
                                        </v-col>
                                        <v-col cols="12">
                                            <v-btn block v-on:click="resetPassword(currentUserEmail)">Passwort
                                                zurücksetzen
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" v-if="emailConfirmMsg">
                                            <p>Wir bitten Sie ihre Mail-Adresse zu bestätigen</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" v-if="passwordConfirmMsg">
                                            <p>Ihr Passwort wurde zurückgesetzt. Bitte kontrollieren Sie Ihr
                                                Mail-Postfach.</p>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>
import firebase from 'firebase';
import {mapActions, mapState} from 'vuex';
import {mapFields} from 'vuex-map-fields';

export default {
    name: 'User',
    data() {
        return {
            rules: {
                required: value => !!value || 'Dieses Feld muss ausgefüllt sein.',
                mail: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Keine gültige Mail Adresse.';
                },
            },
        };
    },
    computed: {
        ...mapState({
            currentUser: state => state.currentUser,
            emailConfirmMsg: state => state.emailConfirmMsg,
            passwordConfirmMsg: state => state.passwordConfirmMsg,
        }),
        ...mapFields({
            currentUserEmail: 'currentUser.email',
        }),
    },
    mounted() {
        let self = this;
        firebase.auth().onAuthStateChanged(function(user) {
            if (user) {
                //flkj
            } else {
                self.$router.push('/');
            }
        });
    },
    methods: {
        ...mapActions({
            logoutUser: 'logoutUser',
            resetPassword: 'resetPassword',
            updateUserMail: 'updateUserMail',
        }),
    },
};
</script>
