<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="3" offset-md="8">
                <v-card tile flat color="rgba(255, 255, 255, 0.9)">

                    <v-card-text>
                        <v-form
                                ref="form"
                                lazy-validation>
                            <v-row dense>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="mail"
                                            label="E-Mail"
                                            prepend-inner-icon="mail"
                                            :rules="[rules.required, rules.mail]"
                                            color="primary">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                            v-model="password"
                                            :append-icon="visiblePassword ? 'visibility' : 'visibility_off'"
                                            :rules="[rules.required]"
                                            :type="visiblePassword ? 'text' : 'password'"
                                            label="Passwort"
                                            hint="Mindestens 8 Zeichen"
                                            color="primary"
                                            @click:append="visiblePassword = !visiblePassword"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="+2">
                                    <v-btn block v-on:click="login">Login</v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import {mapActions} from 'vuex';

export default {
    name: 'Home',
    data() {
        return {
            visiblePassword: false,
            password: '',
            mail: '',
            rules: {
                required: value => !!value || 'Dieses Feld muss ausgefüllt sein.',
                mail: value => {
                    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(value) || 'Keine gültige Mail Adresse.';
                },
            },
        };
    },
    methods: {
        ...mapActions({
            loginUser: 'loginUser',
        }),
        login: function() {
            let loginData = {
                mail: this.mail,
                password: this.password
            }
            this.loginUser(loginData)
        },
    },
};
</script>
