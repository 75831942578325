import {updateField} from 'vuex-map-fields';

export default {
  updateField, // vuex-map-fields
  setCurrentEvent(state, payload){
    state.currentEvent = JSON.parse(JSON.stringify(payload))
  },
  setCurrentNews(state, payload){
    state.currentNews = JSON.parse(JSON.stringify(payload))
  }
}
