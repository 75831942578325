import Vue from 'vue';
import Vuex from 'vuex';
import data from './data';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';

Vue.use(Vuex);

export default new Vuex.Store({
  state: data,
  mutations: mutations,
  getters: getters,
  actions: actions,
  modules: {},
});
