import firebase from 'firebase';
import router from '@/router';
import {firestoreAction} from 'vuexfire';

firebase.initializeApp({
  projectId: "kapelle-schwaegalp",
  apiKey: "AIzaSyCiaJML9dL91JzmL65HHj0CfsisImfaoBQ",
  authDomain: "kapelle-schwaegalp.firebaseapp.com",
  databaseURL: "https://kapelle-schwaegalp.firebaseio.com",
  storageBucket: "kapelle-schwaegalp.appspot.com",
  messagingSenderId: "32172314594",
  appId: "1:32172314594:web:f9559b25c2b6b784"
});

const db = firebase.firestore();

export default {
  /**
   * USER
   */
  loginUser(ctx, payload) {
    return new Promise(function(resolve, reject) {
      firebase.auth().
          signInWithEmailAndPassword(payload.mail, payload.password).
          then(function() {
            ctx.state.loginState = true;
            ctx.dispatch('getCurrentUser')
            router.push({path: '/calendar'});
            resolve();
          }).
          catch(function(error) {
            let errorCode = error.code;
            let errorMessage = error.message;
            console.log(errorCode); // eslint-disable-line no-console
            console.log(errorMessage); // eslint-disable-line no-console
            ctx.state.loginState = false;
            reject();
          });
    });
  },
  logoutUser(ctx) {
    return new Promise(function(resolve, reject) {
      firebase.auth().signOut().then(function() {
        ctx.state.loginState = false;
        router.push({path: '/'});
        resolve();
      }).catch(function(error) {
        console.log('signout nok:' + error.code + '/' + error.message); // eslint-disable-line no-console
        reject();
      });
    });
  },
  getCurrentUser(ctx){
    ctx.state.currentUser = firebase.auth().currentUser
  },
  resetPassword(ctx, payload){
    firebase.auth().sendPasswordResetEmail(payload).then(function() {
      ctx.state.passwordConfirmMsg = true;
      ctx.dispatch('logoutUser')
    }).catch(function(error) {
      console.log('error', error.message) // eslint-disable-line no-console
      // An error happened.
    });
  },
  updateUserMail(ctx, payload){
    ctx.state.currentUser.updateEmail(payload).then(function () {
      ctx.state.emailConfirmMsg = true;
      ctx.state.currentUser.sendEmailVerification().then(function() {
      }).catch(function() {
        // An error happened.
      });
    }).catch(function () {
      // An error happened.
    });
  },

  /**
   * GOTTESDIENSTE
   */
  createEvent: firestoreAction((ctx) => {
    return new Promise(function(resolve, reject) {
      db.collection('events').add({
        date: ctx.getters.getTimeStamp(ctx.state.currentEvent.date,
            ctx.state.currentEvent.time),
        referent: ctx.state.currentEvent.referent,
        refLocation: ctx.state.currentEvent.refLocation,
        relDenom: ctx.state.currentEvent.relDenom,
        name: ctx.state.currentEvent.name || '',
      }).then(function() {
        ctx.state.events = [];
        ctx.dispatch('readEventsAll').then(() => {
          resolve();
        });
      }).catch(function(error) {
        console.error('Error adding document: ', error); // eslint-disable-line no-console
        reject();
      });
    });
  }),
  updateEvent: firestoreAction((ctx, payload) => {
    return new Promise(function(resolve, reject) {
      db.collection('events').doc(payload).set({
        date: ctx.getters.getTimeStamp(ctx.state.currentEvent.date,
            ctx.state.currentEvent.time),
        referent: ctx.state.currentEvent.referent,
        refLocation: ctx.state.currentEvent.refLocation,
        relDenom: ctx.state.currentEvent.relDenom,
        name: ctx.state.currentEvent.name || '',
      }).then(function() {
        ctx.state.events = [];
        ctx.dispatch('readEventsAll').then(() => {
          resolve();
        });
      }).catch(function(error) {
        console.error('Error writing document: ', error);  // eslint-disable-line no-console
        reject();
      });
    });
  }),
  deleteEvent: firestoreAction((ctx, payload) => {
    return new Promise(function(resolve, reject) {
      db.collection('events').doc(payload).delete().then(function() {
        ctx.state.events = [];
        ctx.dispatch('readEventsAll').then(() => {
          resolve();
        });
      }).catch(() => {
        reject();
      });
    });
  }),
  readEventsAll(ctx) {
    return new Promise(function(resolve, reject) {
      let docRef = db.collection('events').orderBy('date');
      docRef.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          if (doc.exists) {
            let newDate = doc.data().date.toDate();
            let newEvent = {};
            newEvent.id = doc.id;
            newEvent.date = JSON.stringify(new Date(newDate)).slice(1,11) ;
            newEvent.time = newDate.toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'});
            newEvent.referent = doc.data().referent;
            newEvent.refLocation = doc.data().refLocation;
            newEvent.name = doc.data().name;
            newEvent.relDenom = doc.data().relDenom;
            newEvent.divider = true;
            newEvent.inset = true;
            // doc.data() is never undefined for query doc snapshots
            ctx.state.events.push(newEvent);
          }
        });
        resolve();
      }).catch(function(error) {
        console.log('Error getting documents: ', error); // eslint-disable-line no-console
        reject();
      });
    });
  },

  /**
   * Vereins News
   */
  createNews: firestoreAction((ctx) => {
    return new Promise(function(resolve, reject) {
      db.collection('associationEvents').add({
        date: ctx.getters.getTimeStamp(ctx.state.currentNews.date,
            ctx.state.currentNews.time),
        location: ctx.state.currentNews.location,
        title: ctx.state.currentNews.title || '',
        description: ctx.state.currentNews.description || '',
      }).then(function() {
        ctx.state.associationNews = [];
        ctx.dispatch('readNewsAll').then(() => {
          resolve();
        });
      }).catch(function(error) {
        console.error('Error adding document: ', error); // eslint-disable-line no-console
        reject();
      });
    });
  }),
  updateNews: firestoreAction((ctx, payload) => {
    return new Promise(function(resolve, reject) {
      db.collection('associationEvents').doc(payload).set({
        date: ctx.getters.getTimeStamp(ctx.state.currentNews.date,
            ctx.state.currentNews.time),
        location: ctx.state.currentNews.location,
        title: ctx.state.currentNews.title || '',
        description: ctx.state.currentNews.description || '',
      }).then(function() {
        ctx.state.associationNews = [];
        ctx.dispatch('readNewsAll').then(() => {
          resolve();
        });
      }).catch(function(error) {
        console.error('Error writing document: ', error);  // eslint-disable-line no-console
        reject();
      });
    });
  }),
  deleteNews: firestoreAction((ctx, payload) => {
    return new Promise(function(resolve, reject) {
      db.collection('associationEvents').doc(payload).delete().then(function() {
        ctx.state.associationNews = [];
        ctx.dispatch('readNewsAll').then(() => {
          resolve();
        });
      }).catch(() => {
        reject();
      });
    });
  }),
  readNewsAll(ctx) {
    return new Promise(function(resolve, reject) {
      let docRef = db.collection('associationEvents').orderBy('date');
      docRef.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          if (doc.exists) {
            let newDate = doc.data().date.toDate();
            let newNews = {};
            newNews.id = doc.id;
            newNews.date = JSON.stringify(new Date(newDate)).slice(1,11) ;
            newNews.time = newDate.toLocaleTimeString('de-DE', {hour: '2-digit', minute: '2-digit'});
            newNews.location = doc.data().location;
            newNews.title = doc.data().title;
            newNews.description = doc.data().description;
            newNews.divider = true;
            newNews.inset = true;
            // doc.data() is never undefined for query doc snapshots
            ctx.state.associationNews.push(newNews);
          }
        });
        resolve();
      }).catch(function(error) {
        console.log('Error getting documents: ', error); // eslint-disable-line no-console
        reject();
      });
    });
  },


};
